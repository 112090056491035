<script setup lang="ts">
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiSpinnerProps {
  flex?: boolean;
  size?: 'sm' | 'md' | 'lg';
  type?: string;
}
const props = withDefaults(defineProps<CiSpinnerProps>(), {
  flex: true,
  size: 'md',
  type: 'primary',
});

/********************
 * REFS & VARS       *
 ********************/
const iconRatio = computed(() => {
  let ratio;
  // let size = this.size;
  switch (props.size) {
    case 'sm':
      ratio = '0.75';
      break;
    case 'lg':
      ratio = '1.5';
      break;
    default:
      ratio = '1';
      break;
  }

  return ratio;
});
</script>

<template>
  <div
    :class="{ flex: props.flex }"
    class="items-center justify-center"
  >
    <CiAwesomeIcon
      :class="`animate-spin fill-${props.type} relative`"
      :icon="faSpinnerThird"
      :ratio="iconRatio"
    />
  </div>
</template>

<style></style>
